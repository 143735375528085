<template>
  <div class="ps-form__content">
    <div class="row mt-3 mb-3" v-if="message">
      <div class="col-12">
        <small class="text-danger" v-if="isError">{{ message }}</small>
        <small class="text-success" v-else>{{ message }}</small>
      </div>
    </div>
    <div class="form-group">
      <label>Name<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" type="text" v-model="form.name" placeholder="" />
        <small v-if="errors && errors.name" class="phone_error text-danger">
          {{ errors.name[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Description<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.description" type="text" />
        <small v-if="errors && errors.description" class="text-danger">
          {{ errors.description[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>About Us </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.about_us" type="text" />
        <small v-if="errors && errors.about_us" class="text-danger">
          {{ errors.about_us[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Owner<sup>*</sup> </label>
      <div class="form-group__content">
        <vue-typeahead-bootstrap
            v-model="form.user_id"
            :ieCloseFix="false"
            :data="users"
            :serializer="item => item.name"
            @hit="user = $event"
            placeholder="Search users"
            @input="lookupUsers"
        />
        <small v-if="errors && errors.user_id" class="text-danger">
          {{ errors.user_id[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Abo<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.in_stock" type="text" />
        <small v-if="errors && errors.in_stock" class="text-danger">
          {{ errors.in_stock[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Status<sup>*</sup> </label>
      <div class="form-group__content">
        <select class="form-control" v-model="form.status">
          <option value="0">Pending</option>
          <option value="1">Active</option>
        </select>
        <small v-if="errors && errors.status" class="text-danger">
          {{ errors.status[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <label>Price<sup>*</sup> </label>
      <div class="form-group__content">
        <input class="form-control" v-model="form.price" type="text" />
        <small v-if="errors && errors.price" class="text-danger">
          {{ errors.price[0] }}
        </small>
      </div>
    </div>
    <div class="form-group">
      <button class="ps-btn" @click.prevent="onSubmit()">
        {{ isLoading ? "Submitting..." : "Submit" }}
        <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
        <span v-if="isLoading" class="spinner-border text-light" role="status"></span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import {debounce} from "lodash";
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
export default {
  name: "ShopForm",
  components: {
    VueTypeaheadBootstrap
  },
  props: {
    shopId: Number
  },
  data() {
    return {
      shop: null,
      users: [],
      term: '',
      form: {
        price: null,
        name: '',
        about_us: '',
        status: '',
        user_id: ''
      },
      show: true,
      isLoading: false
    }
  },
  async created() {
    axios.get('shops/categories').then((response) => {
        this.categories = response.data;
    });

    if (this.shopId) {
      axios.get('shops/' + this.shopId).then((response) => {
        this.shop = response.data;
        this.form.name = this.shop.name;
        this.form.about_us = this.shop.about_us;
        this.form.description = this.shop.description;
        this.form.status = this.shop.status;
        this.form.user_id = this.shop.user_id;
      });
    }
  },
  methods: {
    async onSubmit() {
      if (this.shopId) {
        await this.updateShop()
      } else {
        await this.createShop()
      }
    },

    createShop() {
      this.isLoading = true;
      axios.put('shops/', this.form).then((response) => {
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push({'name': 'admin_shops'})
        }, 1000)
      }).catch((error) => {
        this.isLoading = false;
      });
    },

    updateShop() {
      this.isLoading = true;
      axios.put('shops/' + this.shopId, this.form).then((response) => {
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push({'name': 'admin_shops'})
        }, 1000)

      }).catch((error) => {
        let message = '';
        this.isLoading = false;
      });
    },

    lookupUsers: debounce(function() {
      axios.get('users?term=' + this.term).then((response) => {
        this.users = response.data;
      });
    }, 500)
  }
}
</script>

<style scoped>

</style>
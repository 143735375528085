<template>
  <div>
    <!-- Page Heading -->
    <div class="ps-form__header">
      <h3>Edit Shop</h3>
    </div>

    <!-- Content Row -->
    <ShopForm :product-id="$route.params.id"></ShopForm>

  </div>
</template>

<script>
import ShopForm from "./ShopForm";
export default {
  name: "EditShop",
  components: {ShopForm},
}
</script>

<style scoped>

</style>